import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Doctrine from "../../Components/Doctrine";
import Tittle from "../../Components/Tittle";
import Videolist from "../../Components/VideoList";
import Navbar from "../../Navigation";

function Goals(){

    return (
      <div>
      <Helmet>
        <title>中国正义民主党的战略目标</title>
        <meta name="中国正义民主党奋斗的目标" content="中国正义民主党未来的奋斗目标，战略目标是实现司法独立，社会公平，民主、法治." />
      </Helmet>
      <RegDiv padding="6%" width="90vw">
        <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv  >
            <RegDiv height="2vw" ></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>

        <RegDiv  flexdirection="row">        
          <RegDiv padding="2%" width="450px" mobiledisplay="none">
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
            <RegDiv>
              <Videolist/>
            </RegDiv>
          </RegDiv>
          <RegDiv padding="2%">  
            <h1>战略目标</h1>       
            <p> 1. 2028年前争取实现中国的司法独立：司法不受各级政府干扰，政府行政必须遵守司法判决，法院不再有党委，
              法官资质由独立司法委员会评判，司法委员会由民间执业律师组成。 法官判案中的腐败、无能行为可以被律师提交到司法委员会来决定其法官资格是否能保持。</p>
            <p> 2. 司法独立后 2 年争取实现社会的基本公平</p>
            <p> 3. 司法独立后2 年内实现人民的言论自由，媒体自由，基本保证个人的民主权利得到尊重</p>
            <p> 4. 司法独立后的 4 年内实现各级政权的民主选举和选举的公平性、透明性</p>
          </RegDiv>
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      </div>
  ); 
    
}

export default Goals;