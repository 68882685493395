import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Doctrine from "../../Components/Doctrine";
import Tittle from "../../Components/Tittle";
import Videolist from "../../Components/VideoList";
import Navbar from "../../Navigation";

function Guideline(){
    return (
      <div>
      <Helmet>
        <title>中国正义民主党的纲领和主张</title>
        <meta name="中国正义民主党的纲领" content="中国正义民主党的纲领，坚持司法独立，司法公正，坚持宪政共和， 反对独裁、专制，反对个人专权" />
      </Helmet>
      <RegDiv padding="6%" width="90vw">
         <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv  >
            <RegDiv height="2vw" ></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>
        <RegDiv  flexdirection="row"> 
            
        <RegDiv padding="2%" width="450px" mobiledisplay="none">
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
            <RegDiv>
              <Videolist/>
            </RegDiv>
        </RegDiv>       
          <RegDiv padding="2%">  
            <h1>党的纲领和主张</h1>       
            <p> 1. 坚持司法独立，司法公正，坚持宪政共和</p>
            <p> 2. 坚持民主、法治、自由、平等，反对独裁、专制，反对个人专权</p>
            <p> 3. 坚持宪法的尊严和地位，反对任何政党和组织、个人凌驾于宪法法律之上的行为</p>
            <p> 4. 坚持伸张正义，辅助弱势群体，反对政府的违法行为</p>
            <p> 5. 支持并努力参与和领导民众的维权和抗争运动，推动社会进步</p>
          </RegDiv>

        </RegDiv>
       
      
      </RegDiv>
      </div>
  ); 
    
}

export default Guideline;