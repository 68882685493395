import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Tittle from "../../Components/Tittle";
import Navbar from "../../Navigation";
import Videolist from "../../Components/VideoList";
import Doctrine from "../../Components/Doctrine";

function HomePage(){

    return (
      <div>
      <Helmet>
        <title>中国正义民主党成立</title>
        <meta name="中国正义民主党宗旨和成立的背景" content="党的宗旨，为什么要成立中国正义民主党，为什么要加入中国正义民主党，反映中国现实的视频." />
      </Helmet>
      <RegDiv padding="6%" width="90vw">
        
        <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv>
            <RegDiv height="2vw"></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>

        <RegDiv flexdirection="row">        
          <RegDiv padding="2%"  width="450px" > 
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
            <RegDiv>
              <Videolist/>
            </RegDiv>
            
          </RegDiv>
          <RegDiv padding="2%">        
            <RegDiv   >  
              <h1>为什么要成立中国正义民主党</h1>       
              <p> 1. 中共腐败专制统治下的中国，社会最缺乏的就是公平正义，每个人中国人都深受其害，然而中国没有任何敢于站出来替人民寻求正义的政党</p>
              <p> 2. 中共的专制独裁严重侵犯了所有公民的正当的民主权利和自由，然而中国没有任何为人民争取民主和自由权利的政党</p>
              <p> 3. 中共把司法体系作为镇压民众的暴力机器，中国完全没有公平的司法，社会没有正义，人民苦不堪言，而司法独立是实现司法公平，社会公正的前提，但中国没有追求司法独立，司法公正的政党</p>
              <p> 4. 中国最大的问题是各级政府违法行政，蔑视和践踏宪法（哪怕是中共自己制定的），严重破坏中国的商业、人文、社会和自然环境，导致经济滑坡，失业严重，假冒伪劣横行，毒食品泛滥，污染严重，而中国没有任何反对政府违法行为的政党</p>
              <p> 5. 中国人民的个人财产和银行存款等都不断受到中共政府的破坏、侵占，没有政党站出来反对中共政府的各种恶行</p>
              <p> 6. 中国人民散沙一盘，没有组织，无法抵御中共政府的肆意压榨，必须组织起来</p>
              <p> 正义民主党的成立就是为追求正义、民主，为建立独立司法，社会公正，反对政府违法行为，组织民众争取自己的合法权益，为了给中国一个真正的自由、法治、民主的社会氛围，为了中华民族的重生 </p>
            </RegDiv>
            <RegDiv  >  
            <h1>为什么你应该加入中国正义民主党？</h1>       
            <p> 如果你追求司法公正、社会公平，如果你想保障自己的合法权益、民主权利，如果你想拥有法治保证的人身自由、言论自由、思想自由，如果你想让自己的祖国强大繁荣，如果你反对独裁专制，你需要加入正义民主党！</p>
            <p> 过去几十年，在中共腐败专制的非法统治下，无数人由于发出了不同的声音被以寻衅滋事罪抓捕，无数人被政府强拆失去了财产，无数人由于信仰被迫害，无数人由于政府官员的胡作非为而蒙受冤屈，如果你是其中的受害者或者你不想让这些发生在你身上，你需要加入正义民主党！</p>
            <p> 在过去的3年中，无数人因为中共的疫情隐瞒而得病甚至丧失生命，无数人因为中共野蛮的封城而失去自由和生命，无法看病，经受饥饿，失去工作和工资来源，失去生意和收入，如果你是直接或间接的受害者或是受害者的家属，你需要加入正义民主党！</p>
            <p> 习近平上台后，为了个人的独裁统治，打击私营企业，与西方国家为敌，和世界经济脱钩，导致大批私营企业倒闭，大量人员失业，如果你失去了自己的企业或工作，如果你找不到工作，你需要加入正义民主党！</p>
            <p> 习近平的个人独裁，加剧了中共和人民的对立，将共产党推向死亡道路，完全不顾共产党的整体利益和普通党员的个人权益，如果你是中共党员，你应该加入反独裁阵营，加入正义民主党！</p>
            <p> 习近平的独裁是中国目前最大的危险，是把中华民族推向灾难的邪恶力量，如果你想终结习近平的独裁统治，如果你还承认自己是中国人，你应该加入正义民主党！</p>
          </RegDiv> 
          </RegDiv>
          
        </RegDiv>
        
      
      </RegDiv>
      </div>
  ); 
    
}

export default HomePage;