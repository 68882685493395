import {RegDiv} from "../../Components";
import { Link } from 'react-router-dom';

function Videolist(){

    return(
        <RegDiv >  
              <h3> <Link target="_blank" to="https://www.vpncenter.com/best-vpn/?lp=default&utm_source=microsoft&utm_medium=cpc&sgv_medium=search&utm_campaign=429546961&utm_content=1274334841023229&utm_term=79646417814724&cid=79646077751706&pl=&feeditemid=&targetid=kwd-79646417814724:loc-32&mt=e&network=o&device=c&adpos=&p1=&p2=&geoid=125351&msclkid=6becdfac50c81a83db19bbb48debb8d9">下载VPN</Link></h3>

              <h2>视频</h2>       
              <p> 部分反映中国现实的视频</p>
              
               <ul >
               <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.youtube.com/watch?v=G2o-XH9_xKs">独裁者的谎言与现实对比</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1fj8su56cs13YdEvUshY0NKo81441c">胡鑫宇案件，疫情</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1farmuq98105uyMIEm6Ik8nEP1qi1c">学生集体感染病毒</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1flklqekfla5XlFaTTaEv75Yy1en1c">胡鑫宇案件</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1ffnon72bl04FldFKpf1sRIZJ1dj1c">中国疫情真相</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1feanbcfn3m6zmqIAhWd2ZqUL1qp1c">新疆大火真相</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1f90u07enqo3h8K3H9dC8J1RZ10u1c">野蛮清零酿几十条人命</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1fjuehktkbd5oDTjZBxiYGOcR1b31c">经济真相</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1f32uj7a8001k9hxHk8dvYwRc17d1c">儲戶的錢去哪了？</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1f7l38tvloo2V7E4OfoJi4w6Z1lb1c">中共維穩費高於軍費 10年倍數成長</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1fl7bs94slh7jzSZ07PlG54Me1ti1c">各地掀起退保和不繳社保的熱潮</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1fh3m221jjm50pKxTn6XmUcXz1e21c">徐州鎖鏈八孩母親(徐州鐵鍊女事件)</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1fgvlgkrtua4Ef4ns7n6ERXNP12e1c">强拆视频</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1famm65dg285CFomS3u8wBpDa1vb1c">野蛮封城乱象</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/video/1fei7b9nonb3yhqMn5N5jYEc61r81c">民众觉醒</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.ganjing.com/live/1fh9r9f8mem1IQMVTMZmzdeVt1rs1c">习近平的烂尾工程</Link>
            </li>
            <li style={{ marginRight: "20px" }}>
              <Link target="_blank" to="https://www.youtube.com/watch?v=dmcaMOXPYS4">香堂拆迁</Link>
            </li>
          </ul>        
            </RegDiv>
    )
}

export default Videolist;