import {RegDiv} from "../../Components";

function Doctrine(){

    return(
        <RegDiv>
              <h1 >正义民主党的宗旨</h1>    
              <h3> &nbsp; &nbsp;  &nbsp; &nbsp; 推动中国社会建立独立公正的司法，促进社会公平正义，
                保障每个人的个人民主权利和自由 </h3>        
                <h4>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      -- 冯  泽</h4>
            </RegDiv>
    )
}

export default Doctrine;