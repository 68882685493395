import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Doctrine from "../../Components/Doctrine";
import Tittle from "../../Components/Tittle";
import Videolist from "../../Components/VideoList";
import Navbar from "../../Navigation";

function Membership(){

    return (
      <div>
      <Helmet>
        <title>如何加入中国正义民主党</title>
        <meta name="中国正义民主党成员" content="本党是一个开放性的全民的政党，所有人，不论在大陆还是在海外，香港， 澳门，台湾，只要支持本党的政治理念，就可以视为为本党的成员。" />
      </Helmet>
      <RegDiv padding="6%" width="90vw">
       <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv  >
            <RegDiv height="2vw" ></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>
        <RegDiv  flexdirection="row">        
        <RegDiv padding="2%" width="450px" mobiledisplay="none">
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
            <RegDiv>
              <Videolist/>
            </RegDiv>
          </RegDiv>
          <RegDiv padding="4%">  
            <h1>党员构成</h1>       
            <p> 1. 本党是一个开放性的全民的政党，所有人，不论在大陆还是在海外，香港，
                澳门，台湾，只要支持本党的政治理念，就可以视为为本党的成员。</p>
            <p> 2. 欢迎中共党员，公务员，战士，警察等各界人士加入本党，只要追
                求司法独立、公正、社会公平、民主自由理念，并且愿意为这个理念而行动</p>
            <p> 3. 本党的干部：成为干部需要首先注册成为后备干部，
                为了安全起见，目前注册只需姓名与所在省份名称，
                发送到chinajusticeorg@gmail.com。
                注册后即成为候补干部。要成为正式干部，需要积极参与党的组建，宣传司法独立，司法公正的理念，
                积极推动社会进步，积极帮助社区民众和个人维护正当权益，积极发动和参与维权抗争活动，联络群众和发展党员，
                获得民众认可。收集到2000个签名后，发送到chinajusticeorg@gmail.com，记录后即成为正式干部。
                等到抗争胜利参与选举时即可代表正义民主党参与各级政府职务的选举。</p>
            <p> 4. 党的领导人：目前阶段，本党不设领导人，未来由本党干部举行投票来选择本党的领导人及党内的各个要职人选。</p>
            <p> 5. 正义民主党的发起人： 冯 泽</p>
             
          </RegDiv>
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
          
        </RegDiv>
      
      </RegDiv>
      </div>
  ); 
    
}

export default Membership;