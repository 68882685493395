import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Doctrine from "../../Components/Doctrine";
import Tittle from "../../Components/Tittle";
import Videolist from "../../Components/VideoList";
import Navbar from "../../Navigation";

function Tactics(){

    return (
      <div>
      <Helmet>
        <title>中国正义民主党的行动计划</title>
        <meta name="中国正义民主党行动计划及党员的行动指南" content="中国正义民主党党员应该以宣扬司法独立公正，争取社会公平为主要目标，积极参与各地的维权抗争活动." />
      </Helmet>
      <RegDiv padding="6%" width="90vw">
        <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv  >
            <RegDiv height="2vw" ></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>
        <RegDiv  flexdirection="row">        
        <RegDiv padding="2%" width="450px" mobiledisplay="none">
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
            <RegDiv>
              <Videolist/>
            </RegDiv>
          </RegDiv>
          <RegDiv padding="2%">  
            <h1>行动计划</h1>   
            <p>所有正义民主党的党员、干部和支持者们，请按照下列方式，进行力所能及的行动：</p>    
            <p> 1. 通过各种媒介，宣传正义民主党的主张</p>
            <p> 2. 提供各种 VPN 工具，方便大陆人民访问国外网站，获取真实信息</p>
            <p> 3. 通过技术手段，在中国各级政府的宣传网站和社区网站张贴正义民主党的宗旨和
                  纲领</p>
            <p> 4. 积极印发宣传资料，传递真相，揭露中共当局的违法行
                为，独裁行为，腐败行为，宣传司法公正，民主自由</p>
            <p>5. 积极支持、参与和领导各种抗争和维权运动，扩大正义民主党的影响力，
                  唤醒大众的觉悟，引导大家从争取个人权益上升到争取大众权益，争取司法
                  独立，社会公平</p>
            <p>6. 积极组织罢工罢课罢市等活动，迫使当局放松对人民言论
            自由，结社自由的控制，争取示威游行的权利
            </p>
            <p>7. 积极组织示威游行，公开演讲，宣传司法独立，要求司法公正，占领道德高地，迫使当局实行司法独立</p>
          </RegDiv>
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
          
        </RegDiv>
      
      </RegDiv>
      </div>
  ); 
    
}

export default Tactics;