import {RegDiv} from "../../Components";
import Tittle from "../../Components/Tittle";
import Navbar from "../../Navigation";

function Speakings(){

    return (
      <RegDiv padding="6%" width="90vw">
        <RegDiv flexdirection="row" >
          <RegDiv width="450px"  >
            <Tittle/>
          </RegDiv>
          <RegDiv  >
            <RegDiv height="2vw" ></RegDiv>
            <Navbar/>
          </RegDiv>
        </RegDiv>
        <RegDiv flexdirection="row"  >  
            <RegDiv width="30%"></RegDiv>
            <p>演讲最重要的是能够打动听众的心，必须从当下运动参与者的利益相关方面讲起，引起共鸣，并进而上升到我们对司法公正、民主自由的政治诉求，并力求打动警察、士兵等维稳人员，将其列为同一阵线战友，瓦解中共的底层力量。下面是2篇演讲范例。</p>
            <RegDiv  width="30%"></RegDiv>
        </RegDiv>
        <RegDiv flexdirection="row"  >        
          <RegDiv padding="2%">  
          <RegDiv padding="5%" >      
            <h1 >纪念乌鲁木齐火灾的死难者</h1>    
            </RegDiv>
            <p>同胞们，今天我们聚集在这里，是为了纪念乌鲁木齐火灾的死难者。我们不能忘记他们，因为每
                一个生命都需要得到尊重。我们必须记住,这场灾难发生的原因是荒唐的清零政策，野蛮的封控隔
                离。我们需要认识到，我们每一个人，以及我们的家人，都有可能是下一个受害者。我们愿意成
                为受害者吗？请回答！！！大声回答！！！
                </p>
                <p>
                是的，我们不想成为下一个受害者。那我们该怎么办？只有站起来，走上街头，自行解封，结束
                这个荒唐的清零政策。强制核酸是侵犯我们个人的选择自由，是违反宪法的行为，是政府和官员
                的违法行为。强制隔离是违法行为，强制转运拉入方舱是违法行为，焊死楼门、单元门是违法行
                为，不让下楼，不让出小区是违法行为，不让上班，不让去医院是违法行为。这些违法行为导致
                了多少同胞跳楼，饿死，病死，导致了多少人没有了收入来源，没有饭吃？我们哪一位不是受害
                者？我们不想继续成为受害者，绝不！我们要怎么办？怎么办？我们要走上街头，喊出来，解
                封！解封！解封！
                </p>
                <p>
                我们今天聚集在这里，首先是为了自己的生存，我们需要自由。需要出门的自由，需要工作的自
                由，需要看病的自由，需要吃饭的自由，需要上学的自由。我们还需要言论自由，把我们的痛苦
                说出来，把我们的不满说出来。停止违法行政，停止独裁，还我们自由。停止独裁！！！还我自
                由！！！
                </p>
                <p>
                周围的警察和士兵们，你们也是人，你们也有家人，他们也被封控，也可能是 下一个火灾的受害
                者，他们也无法正常上班，无法就医，他们也失去了自由，想想你们的家人吧！你们是人民警
                察，人民军队，就不要镇压人民，不做独裁者的打手，不做独裁者的走狗，加入人民，脱下那身
                制服吧！我们欢迎你们！我们需要你们！请加入我们，加入人民的阵营！
                </p> 
                <p>
                中国是个伟大的国家，中国人民是伟大的民族，我们配得上享有民主，法治，公平，自由。我们
                不需要独裁者，我们不需要皇帝。我们需要司法独立，需要民主选举。所有的警察，军人，普通
                的共产党员们，你们也都是独裁的受害者，我们需要站在一起，反对独裁，让独裁者下台！独裁
                者,下台！！！独裁者，下台！！！下台！下台
                </p>
          </RegDiv>
          <RegDiv padding="2%">  
            <RegDiv padding="5%" >  
            <h1>让我们继续抗争</h1>  
            </RegDiv>     
            <p> 同胞们，我们前段时间的抗争今天终于有了成果，当局被迫放松了清零政策。但是，大家不能停
                止抗争，因为，现在当局还在疯狂地抓捕前段时间参与抗争的同胞，正是由于这些同胞的抗争，
                才有了现在的放松。我们不能置他们于不顾，不能让为我们争取自由的人成为受迫害者，如果我
                们不争取他们的获释，我们不配享受抗争的成果。</p>
            <p> 前段时间的抗争始于纪念乌鲁木齐火灾的死难者。我们必须清醒地认识到, 灾难发生的直接原因虽
                然是荒唐的清零政策，野蛮的封控隔离。但是，根本的原因是独裁者的肆意妄为。如果我们不能
                继续抗争，不改变中国的独裁专制，这样的灾难还会发生。想一想吧，我们现在还在被迫接受核
                酸，还有所谓的健康码在控制着我们，让我们无法正常生活。独裁者明天拍一下脑袋，新的更严
                厉的管控就会到来。</p>
            <p> 我们今天遭受的痛苦都是这个独裁专制制度造成的，我们今天现场的很多人失业，生意关门，失
                去了收入来源，都是因为独裁者对全社会的封控，对经济的打击，对国家正常发展的破坏造成
                的。是独裁专制政权侵犯我们个人的合法权利，违反宪法造成的。这些违法行为导致了多少同胞
                跳楼，饿死，病死，导致了多少人没有了收入来源，没有饭吃？我们哪一位不是受害者？我们不
                想继续成为受害者，绝不！我们要怎么办？怎么办？我们要走上街头，喊出来，停止违法，停止
                违法！独裁者下台！下台！</p>
            <p> 现在虽然部分解封，但是我们还受到当局很多不合法的限制，我们的口还被当局封着，我们的媒
                体还被当局违法管控，不能自由报道，我们的很多律师和记者还被当局违法关着，不能自由活
                动。他们是我们这个社会的良心，我们需要他们出来，需要他们为我们发声。我们几天就是要为
                他们也为我们争取自由，我们需要言论自由，把我们的痛苦说出来，把我们的不满说出来。停止
                当局的违法行为，停止独裁，还我们自由。停止独裁！！！还我自由！！！</p>
            <p> 警察和士兵们，你们也是人，你们也有家人，他们也被管控，也是专制政权的受害者，他们也失
                去了自由，想想你们的家人吧！你们是人民警察，人民军队，就不要镇压人民，不做独裁者的打
                手，不做独裁者的走狗，加入人民，脱下那身制服吧！我们欢迎你们！我们需要你们！请加入我
                们，加入人民的阵营！</p>
            <p> 中国是个伟大的国家，中国人民是伟大的民族，我们配得上享有民主，法治，公平，自由。我们
                不需要独裁者，我们不需要皇帝。我们需要司法独立，需要民主选举。所有的警察，军人，普通
                的共产党员们，你们也都是独裁的受害者，我们需要站在一起，反对独裁，让独裁者下台！独裁
                者,下台！！！独裁者，下台！！！下台！下台

            </p>
          </RegDiv>
           
        </RegDiv>
        <RegDiv  flexdirection="row">  
         
        </RegDiv>
      
      </RegDiv>
  ); 
    
}

export default Speakings;