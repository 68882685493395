import {RegDiv} from "../../Components";

function Tittle(){

    return (
       
        <RegDiv padding="2%" flexdirection="row" mobileflexdirection="row">
            <img src="justice-icon.png" alt="Justice icon " height="60px" width="60px"/> &nbsp; &nbsp; <h3 style={{ color: 'blue'}}>中国正义民主党 </h3>  
        </RegDiv>
 
  ); 
    
}

export default Tittle;