import styled from "styled-components";

export const RegDiv = styled.div`
  width: ${props => props.width ? props.width : "100%"};
  height: ${props => props.height ? props.height : "auto"};
  display: ${props => props.display ? props.display : "flex"};
  flex-direction: ${props => props.flexdirection ? props.flexdirection : "column"};
  padding: ${props => props.padding ? props.padding : "0vw" };
  margin: ${props => props.margin ? props.margin : "0px"};
  align-content: ${props => props.aligncontent ? props.aligncontent : "stretch"};
  background-color: ${props => props.backgroundcolor ? props.backgroundcolor : "Cornsilk"};
  color: ${props => props.color ? props.color : "black"}
  text-align: center;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction:  ${props => props.mobileflexdirection ? props.mobileflexdirection : "column"};
    align-content: center;
    display: ${props => props.mobiledisplay ? props.mobiledisplay : "flex"};
  }
`;
